import { globals } from 'Library/helpers/globals';
import { q, ww } from 'bobjoll/ts/library/dom';
import { Modal } from '../modal';

const showAddonBuyCreditsModal = async () => {
    const { default: template } = await import('Project/ts/templates/addon-buy-credits/addon-buy-credits.hbs');
    const settings = {
        ...globals,
        multilayer: false,
        name: 'addon-buy-credits',
    };

    ww.ModalInstance.print({ ...settings, html: template() }, true);
};

const handleReceivedIframeMessage = (event: MessageEvent) => {
    if (event.data.action === 'session-ready') {
        showAddonBuyCreditsModal();
    } else if (event.data.action === 'purchased') {
        Modal.hide();
    } else if (event.data.action === 'error') {
        console.error('Error buying credits');
    }
};

q('#buyMoreCredits')?.addEventListener('click', showAddonBuyCreditsModal);

ww.addEventListener('message', handleReceivedIframeMessage);
